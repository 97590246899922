<script lang="ts" setup>
import {onMounted, ref, useAttrs, watch} from "vue";

type Emits =
{
    (event: "update:visible", value: boolean): void;
};
type Props =
{
    closable?: boolean;
    visible?: boolean;
};
const emits = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {closable: false, visible: false});
const close = () => emits("update:visible", false);
const shown = ref(false);
const attrs = useAttrs();
defineExpose({close});
defineOptions({inheritAttrs: false});

const container = ref<HTMLElement>();
const show = () =>
{
    const delayed = () =>
    {
        shown.value = props.visible;
        if(props.visible)
        {
            setTimeout(() => container.value!.scrollTo(0, 0), 50);
        }
    };
    setTimeout(() => delayed(), 50);
};
watch(() => props.visible, () => show(), {flush: "post"});
onMounted(() => show());
</script>
<template>
    <Transition enterActiveClass="transition-opacity" enterFromClass="opacity-0" enterToClass="opacity-100" leaveActiveClass="transition-opacity" leaveFromClass="opacity-100" leaveToClass="opacity-0">
        <div aria-modal="true" class="fixed bg-black bg-opacity-50 h-100% left-0 top-0 w-100% z-1" v-if="shown"/>
    </Transition>
    <div class="fixed grid grid-items-center grid-justify-center h-100vh left-0 overflow-y-auto top-0 pointer-events-none w-100vw z-2" ref="container">
        <Transition enterActiveClass="transition-opacity,transform" enterFromClass="opacity-0 transform-scale-50" enterToClass="opacity-100 transform-scale-100" leaveActiveClass="transition-opacity,transform" leaveFromClass="opacity-100 transform-scale-100" leaveToClass="opacity-0 transform-scale-50">
            <div class="bg-white b-rounded-4 min-w-[min(50em,calc(100vw-4em))] pointer-events-auto relative" v-bind="attrs" v-if="shown">
                <div class="absolute color-gray cursor-pointer hover:color-black i-fa6-solid:xmark right-4 top-4 z-2" v-if="closable" v-on:click="close"/>
                <div class="m-9">
                    <slot v-bind:close="close"/>
                </div>
            </div>
        </Transition>
    </div>
</template>