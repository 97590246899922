import Project, {navigation} from "./Project.vue";
import {AnalyzeRoute} from "../analyze/AnalyzeRoute";
import {AssistantRoute} from "../assistant/AssistantRoute";
import DynamicLayout from "../layouts/DynamicLayout.vue";
import {IndicatorRoute} from "../indicator/IndicatorRoute";
import PageLayout from "../layouts/PageLayout.vue";
import ProjectEdit from "./ProjectEdit.vue";
import ProjectToc from "./ProjectToc.vue";
import Projects from "./Projects.vue";
import {ReportRoute} from "../report/ReportRoute";
import type {RouteRecordRaw} from "vue-router";
import {SurveyRoute} from "../survey/SurveyRoute";
import {id} from "../RouteId";
import {navigation as main} from "../Navigation.vue";

export const ProjectRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Projects,
            path: ""
        },
        {
            children:
            [
                {
                    component: Project,
                    path: "",
                    props: ({params: {projectId}}) => ({projectId})
                },
                {
                    component: ProjectEdit,
                    meta:
                    {
                        breadcrumb: () => "Define"
                    },
                    path: "define/",
                    props: ({params: {projectId}}) => ({projectId})
                },
                {
                    component: ProjectToc,
                    meta:
                    {
                        breadcrumb: () => "Theory of Change"
                    },
                    path: "toc/",
                    props: ({params: {projectId}}) => ({projectId})
                },
                AnalyzeRoute,
                AssistantRoute,
                IndicatorRoute,
                ReportRoute,
                SurveyRoute
            ],
            meta:
            {
                breadcrumb: (texts) => texts["projectId"] ?? "Project",
                navigation
            },
            path: `${id("projectId")}/`
        }
    ],
    component: DynamicLayout,
    meta:
    {
        auth: true,
        breadcrumb: () => "Projects",
        layout: PageLayout,
        navigation: main,
        scopes: ["authenticated", "subscriber"]
    },
    path: "/project/"
};