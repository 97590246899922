import Respondents from "./Respondents.vue";
import type {RouteRecordRaw} from "vue-router";
import Survey from "./Survey.vue";
import Surveys from "./Surveys.vue";
import {id} from "../RouteId";

export const SurveyRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Surveys,
            path: "",
            props: ({params: {projectId}}) => ({projectId})
        },
        {
            children:
            [
                {
                    component: Survey,
                    path: "",
                    props: ({params: {projectId, surveyId}}) => ({projectId, surveyId})
                },
                {
                    component: Respondents,
                    path: "share/",
                    props: ({params: {projectId, surveyId}}) => ({projectId, surveyId})
                }
            ],
            meta:
            {
                breadcrumb: (texts) => texts["surveyId"] ?? "Survey"
            },
            path: `${id("surveyId")}/`
        }
    ],
    meta:
    {
        breadcrumb: () => "Surveys"
    },
    path: "survey/"
};