<script lang="ts" setup>
import {Navigation, useNavigation} from "../Navigation";
import {useBreadcrumb} from "../Breadcrumb";
import {useSWR} from "../SWR";
import {watchEffect} from "vue";

interface Props
{
    secret: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: respondent} = useSWR("/respondent/{secret}/", {secret: props.secret}, {}, null);

const navigation = useNavigation();

watchEffect(() =>
{
    const {secret} = props;
    if(respondent.value === undefined)
    {
        navigation.value =
        [
            {
                disabled: true,
                href: `/y/${secret}`,
                icon: "i-svg-spinners:180-ring-with-bg"
            }
        ];
    }
    else
    {
        navigation.value = respondent.value.map((indicator) =>
        {
            const {id, name} = indicator;
            const navigation: Navigation =
            {
                href: `/y/${secret}/indicator/${id}/`,
                icon: "i-mdi:database-edit-outline",
                text: name
            };
            return navigation;
        });
    }
});

useBreadcrumb();
</script>
<template>
    <router-view/>
</template>