<script lang="ts" setup>
import Toolbar, {type ToolbarButton} from "../components/Toolbar.vue";
import {awaitable, awaitableSelection} from "../components/Awaitable";
import {computed, ref} from "vue";
import Action from "../components/Action.vue";
import Checkbox from "../components/Checkbox.vue";
import ModalQuestion from "../components/ModalQuestion.vue";
import Table from "../components/Table.vue";
import {fetch} from "../API";
import {invokable} from "../components/Invokable";
import {useBreadcrumb} from "../Breadcrumb";
import {useRouter} from "vue-router";
import {useSWR} from "../SWR";

interface Props
{
    projectId: string;
}
const props = withDefaults(defineProps<Props>(), {});

const {data: surveys, mutate} = useSWR("/project/{projectId}/survey/", {projectId: props.projectId}, {}, null);
const router = useRouter();

const adding = ref(false);
const addSurvey = awaitable(adding, async () =>
{
    const {projectId} = props;
    const surveyId = await fetch("post", "/project/{projectId}/survey/", {projectId}, {}, null);
    await router.push(`/project/${props.projectId}/survey/${surveyId}/`);
});

const gotoEdit = async (surveyId: string) =>
{
    const {projectId} = props;
    await router.push(`/project/${projectId}/survey/${surveyId}/`);
};

const gotoShare = async (surveyId: string) =>
{
    const {projectId} = props;
    await router.push(`/project/${projectId}/survey/${surveyId}/share/`);
};

const selected = ref<string[]>([]);
const select = (checked: boolean, surveyId: string) =>
{
    if(checked)
    {
        selected.value.push(surveyId);
    }
    else
    {
        selected.value = selected.value.filter((id) => id !== surveyId);
    }
};

const removing = ref(new Set<string>());
const remove = ref<(() => Promise<void>) | null>(null);
const removeSurveys = invokable(remove, awaitableSelection(removing, async (surveyIds: string[]) =>
{
    const {projectId} = props;
    const promises = surveyIds.map((surveyId) => fetch("delete", "/project/{projectId}/survey/{surveyId}/", {projectId, surveyId}, {}, null));
    await Promise.all(promises);
    surveys.value = surveys.value!.filter(({id}) => !surveyIds.includes(id));
    selected.value = selected.value.filter((id) => !surveyIds.includes(id));
    await mutate();
}));

const toolbar = computed(() =>
{
    const buttons: ToolbarButton[] =
    [
        {
            action: addSurvey,
            icon: "i-material-symbols:add",
            label: "Add survey"
        },
        {
            action: () => removeSurveys([...selected.value]),
            disabled: selected.value.length === 0,
            icon: "i-material-symbols:delete-outline-rounded",
            label: "Remove surveys",
            wait: removing.value.size > 0
        }
    ];
    return buttons;
});

useBreadcrumb();
</script>
<template>
    <h1>Manage surveys</h1>
    <p>Create and manage surveys to share with suppliers or customers to collect GHG Scope 3 data.</p>
    <Toolbar class="m-t-6" v-bind:buttons="toolbar" />
    <Table class="m-t-3" collapse-first collapse-last v-bind:columns="4" v-bind:rows="surveys">
        <template v-slot:header:1>Survey</template>
        <template v-slot:header:2>Added indicators</template>
        <template v-slot:header:3>Shared with</template>
        <template v-slot:column:1="{ row: { id, name } }">
            <Checkbox v-bind:disabled="removing.has(id)" v-bind:value="selected.includes(id)" v-on:update:value="(checked) => select(checked, id)">
                <span class="font-bold">{{name}}</span>
            </Checkbox>
        </template>
        <template v-slot:column:2="{row: {indicators}}">
            <span>{{indicators}}</span>
        </template>
        <template v-slot:column:3="{row: {respondents}}">
            <span v-if="respondents === 0">Not shared yet</span>
            <span v-else>{{respondents}} Respondents</span>
        </template>
        <template v-slot:column:4="{row: {id}}">
            <div class="flex flex-gap-2 flex-justify-center flex-items-center flex-grow flex-row">
                <a aria-busy="true" class="color-green i-svg-spinners:180-ring-with-bg text-6" v-if="removing.has(id)"/>
                <template v-else>
                    <Action icon="i-material-symbols:edit-outline-rounded" label="Edit survey" v-on:action="gotoEdit(id)"/>
                    <Action icon="i-material-symbols:share" label="Share survey" v-on:action="gotoShare(id)"/>
                </template>
            </div>
        </template>
        <template v-slot:placeholder v-if="surveys?.length === 0">
            <div class="flex flex-justify-center">
                <div class="color-lightgray">No surveys created yet</div>
            </div>
        </template>
    </Table>
    <ModalQuestion v-bind:visible="remove !== null" v-on:answer:yes="remove!()" v-on:update:visible="remove = null">
        <template v-slot:title>Are you sure you want to delete {{selected.length}} {{selected.length === 1 ? "survey" : "surveys"}}?</template>
        <template v-slot:text>Once deleted, you won’t be able to recover {{selected.length === 1 ? "it" : "them"}}.</template>
        <template v-slot:yes>Yes, delete {{selected.length === 1 ? "it" : "them"}}</template>
        <template v-slot:no>No, keep {{selected.length === 1 ? "it" : "them"}}</template>
    </ModalQuestion>
</template>