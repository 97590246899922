import Indicator from "./Indicator.vue";
import Indicators from "./Indicators.vue";
import {MeasurementRoute} from "../measurement/MeasurementRoute";
import type {RouteRecordRaw} from "vue-router";
import TelegramQRCode from "../TelegramQRCode.vue";
import {id} from "../RouteId";

export const IndicatorRoute: RouteRecordRaw =
{
    children:
    [
        {
            components:
            {
                default: Indicators,
                sidebar: TelegramQRCode
            },
            path: "",
            props:
            {
                default: ({params: {projectId}}) => ({projectId})
            }
        },
        {
            children:
            [
                {
                    component: Indicator,
                    path: "",
                    props: ({params: {indicatorId, projectId}, query: {suggestion}}) => ({indicatorId, projectId, suggestion})
                },
                MeasurementRoute
            ],
            meta:
            {
                breadcrumb: (texts) => texts["indicatorId"] ?? "Indicator"
            },
            path: `${id("indicatorId")}/`
        }
    ],
    meta:
    {
        breadcrumb: () => "Indicators"
    },
    path: "indicator/"
};