/**
 * Creates a type-safe object from an array of entries.
 *
 * @param entries the entries to create the object from.
 * @returns the object created from the entries.
 */
export function typeSafeObjectFromEntries<const T extends ReadonlyArray<readonly [PropertyKey, unknown]>>(entries: T): {[K in T[number] as K[0]]: K[1]}
{
    return Object.fromEntries(entries) as {[K in T[number] as K[0]]: K[1]};
}
