import {id, secret} from "../RouteId";
import Landing from "./Landing.vue";
import Measure from "./Measure.vue";
import PageLayout from "../layouts/PageLayout.vue";
import Respondent from "./Respondent.vue";
import type {RouteRecordRaw} from "vue-router";
import {navigation} from "./Landing.vue";

export const RespondentRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Landing,
            meta:
            {
                navigation
            },
            path: "",
            props: ({params: {secret}}) => ({secret})
        },
        {
            children:
            [
                {
                    component: Measure,
                    path: "",
                    props: ({params: {indicatorId, secret}}) => ({date: "latest", indicatorId, secret})
                },
                {
                    component: Measure,
                    path: ":date([0-9]{4}-[0-9]{2}-[0-9]{2})/",
                    props: ({params: {date, indicatorId, secret}}) => ({date, indicatorId, secret})
                }
            ],
            component: Respondent,
            meta:
            {
                breadcrumb: () => "Indicator"
            },
            path: `indicator/${id("indicatorId")}/`,
            props: ({params: {secret}}) => ({secret})
        }
    ],
    component: PageLayout,
    meta:
    {
        breadcrumb: () => "Indicators",
        home: ({params: {secret}}) => `/y/${secret}`
    },
    path: `/y/${secret()}`
};