<script lang="ts" setup>
import type {IndicatorFormula, IndicatorFrequency, IndicatorType, Measurement, Unit} from "../../API";
import Checkbox from "../../components/Checkbox.vue";
import Field from "../../components/Field.vue";
import Formula from "./Formula.vue";
import Tex from "../../components/Tex.vue";
import Textbox from "../../components/Textbox.vue";
import {computed} from "vue";
import {useDecimal} from "../../components/Decimal";

interface Indicator
{
    currency?: string;
    description?: string;
    formula?: IndicatorFormula;
    frequency?: IndicatorFrequency;
    measurements?: number;
    name?: string;
    options?: string[],
    type?: IndicatorType;
    unit?: Unit;
}

interface Props
{
    date: string;
    indicator: Indicator;
}

const measurement = defineModel<Measurement | null | undefined>("measurement", {required: true});
const props = withDefaults(defineProps<Props>(), {});

const getChecked = () =>
{
    const {indicator} = props;
    if(measurement.value === null || measurement.value === undefined)
    {
        return indicator!.options!.map(() => false);
    }
    else if(measurement.value.type === "multiple-choice")
    {
        const {value} = measurement.value;
        return indicator!.options!.map((_, index) => value.includes(index));
    }
    else
    {
        return [];
    }
};
const setChecked = (value: boolean[]) => measurement.value = {date: props.date, type: "multiple-choice", value: value.flatMap((selected, index) => selected ? [index] : [])};
const checked = computed({get: getChecked, set: setChecked});

const getFormula = (): number[] =>
{
    if(measurement.value === null || measurement.value === undefined)
    {
        return [];
    }
    else if(measurement.value.type === "formula")
    {
        return measurement.value.value;
    }
    else
    {
        return [];
    }
};
const setFormula = (values: number[]) => measurement.value = {date: props.date, type: "formula", value: values};
const formula = computed({get: getFormula, set: setFormula});

const getMoney = () =>
{
    if(measurement.value === null || measurement.value === undefined)
    {
        return Number.NaN;
    }
    else if(measurement.value.type === "monetary-value")
    {
        return measurement.value.value;
    }
    else
    {
        return Number.NaN;
    }
};
const setMoney = (value: number) => measurement.value = {date: props.date, type: "monetary-value", value};
const money = useDecimal(computed({get: getMoney, set: setMoney}));

const getNumber = () =>
{
    if(measurement.value === null || measurement.value === undefined)
    {
        return Number.NaN;
    }
    else if(measurement.value.type === "number")
    {
        return measurement.value.value;
    }
    else
    {
        return Number.NaN;
    }
};
const setNumber = (value: number) => measurement.value = {date: props.date, type: "number", value};
const number = useDecimal(computed({get: getNumber, set: setNumber}));

const getOption = () =>
{
    if(measurement.value === null || measurement.value === undefined)
    {
        return -1;
    }
    else if(measurement.value.type === "single-choice")
    {
        return measurement.value.value;
    }
    else
    {
        return -1;
    }
};
const setOption = (value: number) => measurement.value = {date: props.date, type: "single-choice", value};
const option = computed({get: getOption, set: setOption});

const getText = () =>
{
    if(measurement.value === null || measurement.value === undefined)
    {
        return "";
    }
    else if(measurement.value.type === "text")
    {
        return measurement.value.value;
    }
    else
    {
        return "";
    }
};
const setText = (value: string) => measurement.value = {date: props.date, type: "text", value};
const text = computed({get: getText, set: setText});

const getUnit = () =>
{
    if(measurement.value === null || measurement.value === undefined)
    {
        return Number.NaN;
    }
    else if(measurement.value.type === "unit")
    {
        return measurement.value.value;
    }
    else
    {
        return Number.NaN;
    }
};
const setUnit = (value: number) => measurement.value = {date: props.date, type: "unit", value};
const unit = useDecimal(computed({get: getUnit, set: setUnit}));

const loading = computed(() => measurement.value === null);

const group = Math.random().toString(36).substring(2);
</script>
<template>
    <Field rule="Money" v-bind:label="indicator.description" v-bind:value="money" v-if="indicator.type === 'monetary-value'" v-slot="{id}">
        <div class="flex flex-items-center">
            <div class="m-r-2 text-gray">{{indicator.currency}}</div>
            <Textbox v-bind:disabled="loading" v-bind:id="id" v-model:value="money"/>
        </div>
    </Field>
    <Field rule="Number" v-bind:label="indicator.description" v-bind:value="number" v-if="indicator.type === 'number'" v-slot="{id}">
        <Textbox v-bind:disabled="loading" v-bind:id="id" v-model:value="number"/>
    </Field>
    <Field rule="NonEmptyString" v-bind:label="indicator.description" v-bind:value="text" v-if="indicator.type === 'text'" v-slot="{id}">
        <Textbox v-bind:disabled="loading" v-bind:id="id" v-model:value="text"/>
    </Field>
    <Field rule="OneOrMoreOptions" v-bind:label="indicator.description" v-bind:value="checked" v-if="indicator.type === 'multiple-choice'">
        <div class="m-t-4" v-bind:key="index" v-for="(option, index) of indicator.options">
            <Checkbox v-bind:disabled="loading" v-bind:name="group" v-bind:label="option" v-bind:value="checked[index]" v-on:update:value="(selected) => checked = checked.map((v, n) => n === index ? selected : v)"/>
        </div>
    </Field>
    <Field rule="OneOption" v-bind:label="indicator.description" v-bind:value="option" v-if="indicator.type === 'single-choice'">
        <div class="m-t-4" v-bind:key="index" v-for="(label, index) of indicator.options">
            <Checkbox v-bind:disabled="loading" v-bind:name="group" v-bind:label="label" v-bind:radio="true" v-model:selected="option"/>
        </div>
    </Field>
    <Field rule="Number" v-bind:label="indicator.description" v-bind:value="unit" v-if="indicator.type === 'unit'" v-slot="{id}">
        <div class="flex flex-items-center">
            <Textbox v-bind:disabled="loading" v-bind:id="id" v-model:value="unit"/>
            <Tex class="m-l-2 text-gray white-space-nowrap" type="unit" v-bind:expression="indicator.unit"/>
        </div>
    </Field>
    <Formula v-bind:date="date" v-bind:formula="indicator.formula!" v-bind:refreshing="loading" v-model:values="formula" v-if="indicator.type === 'formula'"/>
</template>
