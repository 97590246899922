<script lang="ts" setup>
import {computed, ref} from "vue";
import BarChart from "../components/BarChart.vue";
import {DateTime} from "luxon";
import type {Measurements} from "../API";
import PieChart from "../components/PieChart.vue";
import {withCalendarAxis} from "../components/BarChart.vue";

interface Indicator
{
    currency?: string;
    name?: string;
    options?: string[];
    unit?: string;
}
interface Props
{
    indicator?: Indicator;
    labels?: boolean;
    measurements?: Measurements | null;
}
const props = withDefaults(defineProps<Props>(),
{
    indicator: () => ({name: "", options: []}),
    labels: true,
    measurements: null
});

const average = computed(() =>
{
    if(props.measurements === null)
    {
        return null;
    }
    else if(props.measurements.type === "monetary-value" || props.measurements.type === "number" || props.measurements.type === "unit")
    {
        if(props.measurements.values.length === 0)
        {
            return null;
        }
        else
        {
            return props.measurements.values.reduce((sum, {value}) => sum + value, 0) / props.measurements.values.length;
        }
    }
    else
    {
        return null;
    }
});

const calendarAxis = computed(() =>
{
    if(props.measurements === null)
    {
        return null;
    }
    else if(props.measurements.type === "monetary-value" || props.measurements.type === "number" || props.measurements.type === "unit")
    {
        if(props.measurements.values.length === 0)
        {
            return {format: () => "", values: []};
        }
        else
        {
            return withCalendarAxis(props.measurements.values.map(({date, value}) => ({time: DateTime.fromISO(date), value})));
        }
    }
    else
    {
        return null;
    }
});

const decimals = computed(() =>
{
    if(props.measurements === null)
    {
        return 0;
    }
    else if(props.measurements.type === "monetary-value" || props.measurements.type === "number" || props.measurements.type === "unit")
    {
        const ys = props.measurements.values.map(({value}) => value);
        const minY = Math.min(0, ...ys);
        const maxY = Math.max(...ys);
        const magnitude = maxY === 0 && minY === 0 ? 0 : Math.max(Math.log10(0 - minY), Math.log10(maxY));
        const decimals = magnitude > 1 ? 0 : Math.ceil(1 - magnitude);
        return decimals;
    }
    else
    {
        return 0;
    }
});

const pie = computed(() =>
{
    if(props.measurements === null)
    {
        return [];
    }
    else if(props.measurements.type === "single-choice")
    {
        const {options} = props.indicator;
        const counts = props.measurements.values.reduce((map, {value}) => (map.set(value, map.has(value) ? map.get(value)! + 1 : 1), map), new Map<number, number>());
        const values = Array.from(counts.entries()).sort(([a], [b]) => b - a).map(([index, count]) => ({name: options?.[index] || "x", value: count}));
        return values;
    }
    else if(props.measurements.type === "multiple-choice")
    {
        const {options} = props.indicator;
        const counts = props.measurements.values.reduce((map, {value}) => (value.forEach((value) => map.set(value, map.has(value) ? map.get(value)! + 1 : 1)), map), new Map<number, number>());
        const values = Array.from(counts.entries()).sort(([a], [b]) => b - a).map(([index, count]) => ({name: options?.[index] || "x", value: count}));
        return values;
    }
    else
    {
        return [];
    }
});

const samples = computed(() =>
{
    if(props.measurements === null)
    {
        return null;
    }
    else
    {
        return props.measurements.values.length;
    }
});

const trackBar = ref<{x: number; y: number} | null>(null);
const trackPie = ref<{name: string; value: number} | null>(null);

const type = computed(() =>
{
    if(props.measurements === null)
    {
        return null;
    }
    else if(props.measurements.type === "formula" || props.measurements.type === "monetary-value" || props.measurements.type === "number" || props.measurements.type === "unit")
    {
        const items = calendarAxis.value?.values?.length;
        if(items === undefined || items <= 12)
        {
            return "bar";
        }
        else
        {
            return "line";
        }
    }
    else if(props.measurements.type === "single-choice" || props.measurements.type === "multiple-choice")
    {
        return "pie";
    }
    else
    {
        return null;
    }
});

const prefix = computed(() =>
{
    if(props.measurements === null)
    {
        return null;
    }
    else if(props.measurements.type === "monetary-value")
    {
        return props.indicator.currency;
    }
    else
    {
        return null;
    }
});

const suffix = computed(() =>
{
    if(props.measurements === null)
    {
        return null;
    }
    else if(props.measurements.type === "formula" || props.measurements.type === "unit")
    {
        return props.indicator.unit;
    }
    else
    {
        return null;
    }
});
</script>
<template>
    <template v-if="type === 'bar' || type === 'line'">
        <h4 class="gap-4 grid grid-cols-2 grid-items-center" v-if="labels">
            <div v-if="trackBar !== null">
                <span class="color-gray-4">— {{calendarAxis?.format(trackBar.x)}}:&ThickSpace;</span>
                <span class="color-gray white-space-nowrap" v-if="prefix">{{prefix}}&ThickSpace;</span>
                <span class="color-green">{{trackBar.y.toFixed(decimals)}}</span>
                <span class="color-gray-4 white-space-nowrap" v-if="suffix">&ThickSpace;{{suffix}}</span>
            </div>
            <div class="grid-col-[2] grid-justify-self-end">
                <span class="color-gray-4">- - Average</span>
                <template v-if="average !== null">
                    <span class="color-gray-4">: </span>
                    <span class="color-gray-4 white-space-nowrap" v-if="prefix">{{prefix}}&ThickSpace;</span>
                    <span class="color-green">{{average.toFixed(decimals)}}</span>
                    <span class="color-gray-4 white-space-nowrap" v-if="suffix">&ThickSpace;{{suffix}}</span>
                </template>
            </div>
        </h4>
        <template v-if="calendarAxis === null">
            <div class="aspect-ratio-3 bg-verylightgray/50 flex flex-items-center flex-justify-center">
                <div class="color-gray">No data.</div>
            </div>
        </template>
        <template v-else>
            <BarChart v-bind="calendarAxis" v-bind:aspect-ratio="3" v-bind:labels="labels" v-bind:type="type" v-on:track="(v) => trackBar = v"/>
        </template>
    </template>
    <template v-if="type === 'pie'">
        <h4 class="gap-4 grid grid-cols-2 grid-items-center" v-if="labels">
            <div v-if="trackPie !== null">
                <span class="color-gray-4"><span class="inline-block m-r-1 overflow-x-hidden v-bottom w-0.5em">●</span> {{trackPie.name}}: </span>
                <span class="color-green">{{trackPie.value}}</span>
            </div>
            <div class="grid-col-[2] grid-justify-self-end" v-if="samples !== null">
                <span class="color-gray-4">● Total: </span>
                <span class="color-green">{{samples}}</span>
            </div>
        </h4>
        <PieChart v-bind:labels="labels" v-bind:values="pie" v-on:track="(v) => trackPie = v"/>
    </template>
</template>
