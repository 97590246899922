<script setup lang="ts">
import {Categories, type Category, type Entry, type GHG, Page, type Template, withGHG} from "./GHG";
import {onMounted, ref} from "vue";
import {fetch} from "../API";
import {findTemplate} from "./TemplateFilter";
import {useRouter} from "vue-router";

interface Props
{
    projectId: string;
}
const props = defineProps<Props>();

const router = useRouter();
onMounted(async () => await router.replace(`/project/${props.projectId}/assistant/`));

const create = async () =>
{
    const {projectId} = props;
    const clone = Object.values(ghg.value.indicators).flat();
    if(clone.length > 0)
    {
        await fetch("post", "/project/{projectId}/indicator/template/clone/", {projectId}, {}, clone);
    }
    await router.push(`/project/${projectId}/indicator/`);
};

const load = async () =>
{
    const list = await fetch("get", "/template/", {}, {fields: ["tags"]}, null);
    return list.map(({id, tags}) => ({id, tags: tags ?? []}));
};

const save = (category: Category, templates: Template[], entries: Entry[]) =>
{
    ghg.value.indicators[category] = entries.map(({name, ...tags}) => ({id: findTemplate(templates, Object.fromEntries(Object.entries(tags).map(([k, v]) => [k, v!])))!, name}));
};

const next = async (page: Page) =>
{
    const {projectId} = props;
    const pages = ["checklist", "sources", ...Categories.filter((key) => ghg.value.indicators[key] !== undefined)] as const;
    const index = pages.indexOf(page);
    const next = pages[index + 1];
    await router.push(`/project/${projectId}/assistant/${next}/`);
};

const progress = (page: Page): number =>
{
    const pages = ["checklist", "sources", ...Categories.filter((category) => ghg.value.indicators[category] !== undefined)] as const;
    const index = pages.indexOf(page);
    const total = pages.length;
    return index / (total - 1);
};

const ghg = ref<GHG>({create, indicators: {indirect: []}, next, progress, save, templates: load()});
withGHG(ghg);
</script>
<template>
    <router-view v-slot="{Component}">
        <keep-alive>
            <component v-bind:is="Component" />
        </keep-alive>
    </router-view>
</template>
