<script lang="ts" setup>
import Tooltip from "./Tooltip.vue";
import {debounce} from "./Debounce";
import {ref} from "vue";

type Props =
{
    text: string
};
const props = withDefaults(defineProps<Props>(), {});
const copied = ref(false);
const copy = async () =>
{
    await navigator.clipboard.writeText(props.text);
    copied.value = true;
    reset();
};
const reset = debounce(2000, () => copied.value = false);
</script>
<template>
    <Tooltip v-on:click.prevent="copy">
        <template v-slot:default>
            <slot v-bind:copied="copied"/>
        </template>
        <template v-slot:tooltip>
            <template v-if="copied">Copied to clipboard</template>
            <template v-else>Click to copy</template>
        </template>
    </Tooltip>
</template>