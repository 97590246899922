<script lang="ts" setup>
</script>
<template>
    <div class="flex flex-col min-h-full">
        <div class="flex-grow">
            <slot/>
        </div>
        <div class="bg-[linear-gradient(rgba(255,255,255,0.75),white_100%)] bottom-0 flex flex-gap-3 flex-justify-center p-4 sticky">
            <slot name="buttons"/>
        </div>
    </div>
</template>