import {type Ref, computed, ref, watchEffect} from "vue";

export function useDecimal(number: Ref<number>)
{
    const string = ref("");
    watchEffect(() =>
    {
        if(Number.isFinite(number.value))
        {
            string.value = String(number.value);
        }
        else
        {
            string.value = "";
        }
    });
    const getString = () => string.value;
    const setString = (value: string) =>
    {
        if(/^-?[0-9]+(\.[0-9]*)?$/.test(value))
        {
            number.value = Number(value);
        }
        else
        {
            number.value = Number.NaN;
        }
        string.value = value;
    };
    return computed({get: getString, set: setString});
}
