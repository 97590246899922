import type {IndicatorType} from "../../API.ts";

/**
 * Creates an empty measurement for the specified indicator type.
 *
 * @param type the indicator type.
 * @returns the empty measurement.
 */
export function createEmptyMeasurement(type: IndicatorType)
{
    switch(type)
    {
        case "formula":
        case "multiple-choice":
        {
            return {date: "", type, value: []};
        }
        case "monetary-value":
        case "number":
        case "unit":
        {
            return {date: "", type, value: Number.NaN};
        }
        case "single-choice":
        {
            return {date: "", type, value: -1};
        }
        case "text":
        {
            return {date: "", type, value: ""};
        }
        default:
        {
            return type;
        }
    }
}