<script setup lang="ts">
import {type Template, useGHG} from "../GHG";
import {computed, onMounted, ref} from "vue";
import Button from "../../components/Button.vue";
import Dropdown from "../../components/Dropdown.vue";
import Field from "../../components/Field.vue";
import Table from "../../components/Table.vue";
import Textbox from "../../components/Textbox.vue";
import Validation from "../../components/Validation.vue";
import {awaitable} from "../../components/Awaitable";
import {filterTemplates} from "../TemplateFilter";
import {useProgressBar} from "../../Wizard";

const ghg = useGHG();
const page = "indirect";

const templates = ref<Template[]>();
onMounted(async () => templates.value = await ghg.value.templates);

const wait = ref(false);
const skip = awaitable(wait, async () => await ghg.value.create());
const next = () =>
{
    ghg.value.save(page, templates.value!, rows.value.map(({name, utility, ...rest}) => ({name: `${name} – ${utility}`, utility, ...rest})));
    skip();
};
useProgressBar(() => ghg.value.progress(page));

const utilities = computed(() => templates.value === undefined ? [] : filterTemplates(templates.value, {}, "utility"));
const regions = (utility: string) => templates.value === undefined ? [] : filterTemplates(templates.value, {utility}, "region");
const providers = (utility: string, region: string) => templates.value === undefined ? [] : filterTemplates(templates.value, {region, utility}, "provider");

interface Row
{
    name: string;
    provider: string | null;
    region: string | null;
    utility: string | null;
}
const rows = ref<Row[]>([{name: "", provider: null, region: null, utility: null}]);

const add = () => rows.value.push({name: "", provider: null, region: null, utility: null});
const remove = (index: number) => rows.value.splice(index, 1);
</script>
<template>
    <h1>Select all Scope 2 Indirect Emissions</h1>
    <p>Add all electric or gas companies your facilities use to purchase electricity or gas.</p>
    <Validation>
        <template v-slot:default="{validate}">
            <Table collapse-last v-bind:columns="5" v-bind:loading="templates === undefined" v-bind:rows="templates === undefined ? [] : rows">
                <template v-slot:header:1>Location</template>
                <template v-slot:header:2>Utility</template>
                <template v-slot:header:3>Region</template>
                <template v-slot:header:4>Service provider</template>
                <template v-slot:column:1="{row}">
                    <Field class="flex-grow" rule="NonEmptyString" v-bind:value="row.name" v-slot="{id}">
                        <Textbox v-bind:id="id" v-model:value="row.name" placeholder="Main office"/>
                    </Field>
                </template>
                <template v-slot:column:2="{row}">
                    <Field class="flex-grow" rule="NotNull" v-bind:value="row.utility" v-slot="{id}">
                        <Dropdown v-bind:id="id" v-bind:items="utilities" v-model:selected="row.utility"/>
                    </Field>
                </template>
                <template v-slot:column:3="{row}">
                    <Field class="flex-grow" rule="NotNull" v-bind:value="row.region" v-slot="{id}">
                        <Dropdown v-bind:id="id" v-bind:items="regions(row.utility!)" v-model:selected="row.region" v-bind:disabled="!row.utility"/>
                    </Field>
                </template>
                <template v-slot:column:4="{row}">
                    <Field class="flex-grow" rule="NotNull" v-bind:value="row.provider" v-slot="{id}">
                        <Dropdown v-bind:id="id" v-bind:items="providers(row.utility!, row.region!)" v-model:selected="row.provider" v-bind:disabled="!row.region || !row.utility"/>
                    </Field>
                </template>
                <template v-slot:column:5="{index}">
                    <div class="flex flex-gap-2">
                        <a aria-label="Delete" class="i-material-symbols:delete-outline-rounded link text-6" v-bind:class="rows.length === 1 ? 'invisible' : 'visible'" v-on:click="remove(index)"/>
                        <a aria-label="Add" class="i-fa6-solid:plus link text-6" v-bind:class="index === rows.length - 1 ? 'visible' : 'invisible'" v-on:click="add()"/>
                    </div>
                </template>
            </Table>
            <div class="flex flex-gap-6 flex-justify-center m-t-6">
                <Button role="primary" type="button" v-bind:loading="templates === undefined || wait" v-on:click="validate(true) && next()">Generate Indicators</Button>
                <a class="link" v-bind:aria-disabled="templates === undefined || wait" v-on:click="skip">Skip</a>
            </div>
        </template>
    </Validation>
</template>
