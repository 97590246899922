<script lang="ts" setup>
import {onMounted, onUnmounted, ref} from "vue";
import {DateTime} from "luxon";

interface Props
{
    time: Date;
}
const props = withDefaults(defineProps<Props>(), {});

const format = () =>
{
    const {time} = props;
    const t = DateTime.fromJSDate(time);
    if(DateTime.now().diff(t).as("minutes") < 1)
    {
        return "Just now";
    }
    else
    {
        return DateTime.fromJSDate(time).toRelative()!;
    }
};
const formatted = ref(format());

let interval: number | undefined = undefined;
onMounted(() => interval = window.setInterval(() => formatted.value = format(), 30000));
onUnmounted(() => window.clearInterval(interval));
</script>
<template>{{formatted}}</template>