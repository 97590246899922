import {type Ref, inject, provide} from "vue";

export interface Entry extends Record<string, string | null>
{
    name: string;
}

export interface Indicator
{
    id: string;
    name: string;
}

export interface Template
{
    id: string;
    tags: string[];
}

export interface GHG
{
    create: () => Promise<void>;
    indicators: {[_ in Category]?: Indicator[]};
    next: (page: Page) => Promise<void>;
    progress: (page: Page) => number;
    templates: Promise<Template[]>;
    save: (category: Category, templates: Template[], rows: Entry[]) => void
}

export const Categories = ["stationary", "mobile", "fugitive", "removal", "indirect"] as const;
export type Category = typeof Categories[number];
export type Page = "checklist" | "sources" | Category;

export function useGHG()
{
    return inject<Ref<GHG>>("ghg")!;
}

export function withGHG(templates: Ref<GHG>)
{
    provide("ghg", templates);
}