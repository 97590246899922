<script lang="ts" setup>
import {computed, watch} from "vue";
import {extractFunctionIndices, extractFunctionParameters, extractNumber} from "../../../../backend/main/SymbolicMath";
import Decimal from "../../components/Decimal.vue";
import Field from "../../components/Field.vue";
import type {IndicatorFormula} from "../../API";
import Tex from "../../components/Tex.vue";
import {useFormulaRunner} from "../../components/FormulaRunner";

interface Emits
{
    (event: "update:values", values: number[]): void;
}

interface Props
{
    date: string;
    formula: IndicatorFormula;
    refreshing: boolean;
    values: number[];
}

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {});

const getFormulaValue = (index: number): number => props.values[index + 1] ?? Number.NaN;
const setFormulaValue = (index: number, value: number) =>
{
    const {formula: {parameters}, values} = props;
    const {length} = Object.keys(parameters);
    const variables = new Array(length + 1).fill(0).map((_, n) => n === index + 1 ? value : values[n] ?? Number.NaN);
    emit("update:values", variables);
};

const expression = computed(() => props.formula?.expression ?? "");
const parameters = computed(() =>
{
    const parameters = props.formula?.parameters ?? {};
    const names = extractFunctionParameters([expression.value]);
    return Object.fromEntries(Object.entries(parameters).filter(([name]) => names.has(name)));
});
const variables = computed(() =>
{
    const {date, values} = props;
    const year = date.slice(0, 4);
    const indices = extractFunctionIndices([expression.value]);
    const a = Object.fromEntries(Object.entries(parameters.value).map(([name, {unit}], index) => [name, {unit, value: values[index + 1] ?? Number.NaN}]));
    const b = Object.fromEntries(indices.flatMap(({indices, keys}) => Array.from(indices).map<[string, string[]]>((name) => [name, keys]).map(([name]) => [name, {value: keys.includes(year) ? year : keys[keys.length - 1]}])));
    return {...a, ...b};
});
const {error, result} = useFormulaRunner(expression, variables);
watch(result, (result) =>
{
    if(result !== undefined)
    {
        const {formula: {unit}, values} = props;
        const [existing = Number.NaN] = props.values;
        const [value] = extractNumber([result], unit);
        if(!Object.is(existing, value))
        {
            emit("update:values", [value, ...values.slice(1)]);
        }
    }
});
</script>
<template>
    <template v-bind:key="index" v-for="([parameter, {name, unit}], index) of Object.entries(parameters)">
        <Field rule="Number" v-bind:label="`${name} (${parameter})`" v-bind:value="String(getFormulaValue(index))" v-slot="{id}">
            <div class="flex flex-items-center">
                <Decimal v-bind:disabled="refreshing" v-bind:id="id" v-bind:value="getFormulaValue(index)" v-on:update:value="(value) => setFormulaValue(index, value)"/>
                <Tex class="m-l-2 text-gray white-space-nowrap" type="unit" v-bind:expression="unit"/>
            </div>
        </Field>
    </template>
    <Field label="Result">
        <div class="flex flex-items-center min-h-10">
            <div class="text-pink" v-if="error">{{error}}</div>
            <Tex type="expression" v-bind:expression="result" v-else/>
        </div>
    </Field>
</template>