import Measure from "./Measure.vue";
import type {RouteRecordRaw} from "vue-router";

export const MeasurementRoute: RouteRecordRaw =
{
    children:
    [
        {
            component: Measure,
            path: "",
            props: ({params: {indicatorId, projectId}}) => ({date: "latest", indicatorId, projectId})
        },
        {
            component: Measure,
            path: ":date([0-9]{4}-[0-9]{2}-[0-9]{2})/",
            props: ({params: {date, indicatorId, projectId}}) => ({date, indicatorId, projectId})
        }
    ],
    meta:
    {
        breadcrumb: () => "Indicator"
    },
    path: "measurement/"
};