import type {Path, PathMethod, PathParams, PathQuery, PathResponseBody} from "./Types";

export type * from "./Types";

export type TypedResponse<P extends Path, M extends PathMethod<P>> = Omit<Response, "json"> & {json: () => Promise<PathResponseBody<P, M>>};

/**
 * Creates the API server URL.
 *
 * @param base the URL base path.
 * @param path the API path.
 * @param params the API path parameters.
 * @param query the API query parameters.
 * @returns the API server URL.
 */
export function url<P extends Path, M extends PathMethod<P>>(base: string, path: P, params: PathParams<P, M>, query: PathQuery<P, M>)
{
    const uri = path.replace((/{([^}]+)}/g), (_, k) => (params as Record<any, any>)[k]);
    const url = new URL(`${base}${uri}`);
    const search = new URLSearchParams();
    for(const [key, value] of Object.entries(query as object))
    {
        if(value instanceof Array)
        {
            for(const v of value)
            {
                search.append(`${key}[]`, v);
            }
        }
        else
        {
            search.append(key, String(value));
        }
    }
    url.search = search.toString();
    return url;
}