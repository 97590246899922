<script lang="ts" setup>
import {useRoute, useRouter} from "vue-router";
import ProgressBar from "../components/ProgressBar.vue";
import ResponsiveLayout from "./ResponsiveLayout.vue";
import {computed} from "vue";
import {progress} from "../Wizard";

const route = useRoute();
const router = useRouter();
const back = () => router.back();
const home = computed(() => route.matched.reduce((previous, {meta: {home}}) => home === undefined ? previous : home(route), "/"));
</script>
<template>
    <ResponsiveLayout>
        <template v-slot:default>
            <router-view />
        </template>
        <template v-slot:logo>
            <a class="cursor-pointer flex flex-content-center flex-items-center m-4 parent" to="/" v-on:click="back">
                <span class="color-green parent-hover-color-blue i-material-symbols:arrow-back-ios cursor-pointer m-r-2 text-5"/>
                <span class="color-gray parent-hover-color-black">Back</span>
            </a>
        </template>
        <template v-slot:mobile>
            <a class="cursor-pointer flex flex-content-center flex-items-center m-4 parent" to="/" v-on:click="back">
                <span class="color-green parent-hover-color-blue i-material-symbols:arrow-back-ios cursor-pointer m-r-2 text-5"/>
                <span class="color-gray parent-hover-color-black">Back</span>
            </a>
        </template>
        <template v-slot:profile>
            <router-link class="h-6 i-mdi:close link w-6" v-bind:to="home"/>
        </template>
        <template v-slot:sidebar>
            <router-view name="sidebar"/>
        </template>
        <template v-slot:toolbar>
            <ProgressBar class="self-center" v-bind:progress="progress"/>
        </template>
    </ResponsiveLayout>
</template>
